import React from 'react'
import { Select as BaseSelect, SelectProps } from 'baseui/select'
import { withOverrides } from './overrides'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { ourColors } from './Colors'
import { useStyletron } from 'baseui'

const SelectIcon = () => {
  const [css] = useStyletron()
  return (
    <FontAwesomeIcon
      className={css({
        color: ourColors.utechOrange,
        fontSize: '24px'
      })}
      icon={faCaretDown}
    />
  )
}

const globalOverrides: SelectProps['overrides'] = {
  ControlContainer: {
    style: {
      borderLeftWidth: '1px',
      borderTopWidth: '1px',
      borderRightWidth: '1px',
      borderBottomWidth: '1px'
    }
  },
  SelectArrow: {
    component: SelectIcon
  },
  IconsContainer: {
    style: {
      paddingLeft: '18px',
      paddingRight: '18px',
      borderLeftStyle: 'solid',
      borderLeftWidth: '1px',
      borderLeftColor: ourColors.interfaceGrey,
      ':hover': {
        cursor: 'pointer'
      }
    }
  },
  ValueContainer: {
    style: {
      fontSize: '18px'
    }
  },
  Placeholder: {
    style: {
      fontStyle: 'italic',
      minWidth: '191px'
    }
  }
}

export const Select = withOverrides<SelectProps>(BaseSelect, globalOverrides)
